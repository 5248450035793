<template>
  <div class="container my-4 pt-2">
    <h5 class="alert alert-primary">
      Enter a first and last name or a University ID to display that employee's
      electronic signature.
    </h5>
    <div class="row">
      <div class="col-lg-4 mb-4">
        <form @submit.prevent="submit()">
          <div class="form-group">
            <label for="last_name">Last Name:</label>
            <input
              id="last_name"
              v-model.trim="form.last_name"
              :disabled="!!form.id"
              type="text"
              class="form-control"
              minlength="2"
            />
          </div>
          <div class="form-group">
            <label for="first_name">First Name:</label>
            <input
              id="first_name"
              v-model.trim="form.first_name"
              :disabled="!!form.id"
              type="text"
              class="form-control"
              minlength="2"
            />
          </div>
          <div class="form-group">
            <label for="id">EmplID, NetID, or CatCard number:</label>
            <input
              id="id"
              v-model.trim="form.id"
              :disabled="Boolean(form.first_name || form.last_name)"
              type="text"
              class="form-control"
            />
          </div>
          <button
            type="submit"
            class="btn btn-primary mr-2"
            :disabled="!searchEnabled"
          >
            Search
          </button>
          <button id="reset" class="btn btn-danger" @click.prevent="reset">
            Reset
          </button>
        </form>
      </div>

      <div class="col-lg-8">
        <div v-if="error" class="alert alert-danger" v-text="error"></div>

        <div
          v-if="loading"
          class="h-100 d-flex justify-content-center align-items-center"
        >
          <div
            class="spinner-border text-primary"
            role="status"
            style="height: 150px; width: 150px; font-size: 3rem"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div v-else-if="employee">
          <table class="table">
            <tbody>
              <tr v-for="(value, key) in employee" :key="key">
                <td class="text-nowrap">
                  <strong>{{ key }}</strong>
                </td>
                <td :ref="key">{{ value }}</td>
              </tr>
              <tr>
                <td class="text-nowrap">
                  <strong>Digitized Signature</strong>
                </td>
                <td v-if="signature">
                  <img :src="`data:image/bmp;base64,${signature}`" />
                </td>
                <td v-else>no signature on file</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-else-if="searchResults.length">
          <table class="table">
            <thead>
              <th>Name</th>
              <th>EmplID</th>
              <th>Dept. Name</th>
            </thead>
            <tr v-for="(result, i) in resultsPage" :key="i">
              <td>
                <a href="" @click.prevent="getEmployee(result)">{{
                  result.cn
                }}</a>
              </td>
              <td>{{ result.emplId }}</td>
              <td>{{ result.display.Department }}</td>
            </tr>
          </table>

          <nav v-if="totalPages > 1" aria-label="Search result pagination">
            <ul class="pagination justify-content-center">
              <li
                v-for="i in totalPages"
                :key="i"
                class="page-item"
                :class="{ active: resultsPageIndex === i }"
              >
                <a
                  class="page-link"
                  href=""
                  @click.prevent="resultsPageIndex = i"
                  v-text="i"
                ></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        last_name: "",
        first_name: "",
        id: "",
      },
      error: null,
      employee: null,
      signature: null,
      searchResults: [],
      resultsPageIndex: 1,
      resultPerPage: 7,
      loading: false,
    }
  },
  computed: {
    searchEnabled() {
      return Boolean(
        this.form.id || (this.form.first_name && this.form.last_name),
      )
    },
    resultsPage() {
      const start = (this.resultsPageIndex - 1) * this.resultPerPage
      const end = start + this.resultPerPage
      return this.searchResults.slice(start, end)
    },
    totalPages() {
      return Math.ceil(this.searchResults.length / this.resultPerPage)
    },
  },
  methods: {
    reset() {
      this.searchResults = []
      this.employee = null
      this.form = {
        last_name: "",
        first_name: "",
        id: "",
      }
    },
    async submit() {
      this.error = null
      this.loading = true
      await this.searchEdsPerson()
      this.loading = false
    },
    async getEmployee(employee) {
      this.loading = true
      await this.getSignature(employee.emplId)
      this.employee = employee["display"]
      this.loading = false
    },
    async processSearchResults(response) {
      this.employee = null
      if (response.data === "No results") {
        this.error = "No employees matching query."
      } else if (response.data.length > 1) {
        this.searchResults = response.data
      } else if (response.data.emplId) {
        await this.getEmployee(response.data)
      } else if (response.data[0].emplId) {
        await this.getEmployee(response.data[0])
      }
    },
    async getSignature(uid) {
      const [error, response] = await this.$api.get(`/signature?uid=${uid}`)
      if (error?.response) {
        const { Message } = error.response.data
        this.error = Message
      } else if (typeof error === "string") {
        this.error = error
      } else if (error) {
        this.error = "Please verify UA network connection."
      } else {
        this.signature = response.data
      }
    },
    async searchEdsPerson() {
      const { first_name, last_name, id: uid } = this.form
      let url
      if (uid) {
        url = `/person?uid=${uid}`
      } else {
        if (this.form.first_name.length < 2 && this.form.last_name.length < 2) {
          this.error = "Name fields require a 2 character minimum to search"
          return
        }
        url = `/search?first_name=${first_name}&last_name=${last_name}`
      }
      const [error, response] = await this.$api.get(url)
      if (error?.response) {
        const { Message } = error.response.data
        this.error = Message
      } else if (typeof error === "string") {
        this.error = error
      } else if (error) {
        this.error = "Please verify UA network connection."
      } else {
        await this.processSearchResults(response)
      }
    },
  },
}
</script>
