<template>
  <div class="d-flex flex-column min-vh-100">
    <NavigationBar />
    <AuthGuard>
      <div class="d-flex flex-column">
        <SignatureForm />
      </div>
    </AuthGuard>
    <FNSVFooter class="mt-auto" />
  </div>
</template>

<script>
import FNSVFooter from "./components/FNSVFooter"
import NavigationBar from "./components/NavigationBar"
import SignatureForm from "./components/SignatureForm"
import AuthGuard from "@uarizona-fnsv/vue-doggo/AuthGuard"

export default {
  components: {
    FNSVFooter,
    NavigationBar,
    SignatureForm,
    AuthGuard,
  },
  async beforeMount() {
    await this.$auth.login()
  },
}
</script>
