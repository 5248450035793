import Vue from "vue"
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import Doggo from "@uarizona-fnsv/vue-doggo"
import "@uarizona-fnsv/bootstrap/dist/bootstrap.css"

import App from "./App.vue"
import api from "./utils/api"

function getEnvironment() {
  const hostname = window.location.hostname
  if (hostname.includes("-dev")) return "dev"
  if (hostname.includes("cloudfront.net")) return "review"
  return "prod"
}

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    environment: getEnvironment(),
  })
}

Vue.use(Doggo, {
  amplify: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    domain: process.env.VUE_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.VUE_APP_COGNITO_SIGN_IN_URL,
  },
  disable: process.env.VUE_APP_DISABLE_AUTH,
})

Vue.config.productionTip = false

Vue.prototype.$api = api

new Vue({
  render: (h) => h(App),
}).$mount("#app")
